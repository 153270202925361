<script setup>
import ConfettiExplosion from "vue-confetti-explosion";
</script>
<template>
  <ion-page>
    <syaberu-menu/>
    <ion-content :fullscreen="true">
      <!-- 日に日に増えていくと良いよね。これ。 -->
      <ConfettiExplosion :particleCount="particuleCount" v-if="congrats"/>
      <ion-grid>
        <ion-row>
          <ion-col>
      <transition
        name="custom-classes-transition"
        enter-active-class="animate__animated animate__flipInY"
      >
        <div v-if="show">
          <ion-card class="missionCard">
          <ion-item>
            <ion-label>{{dayX}}</ion-label>
              <ion-button slot="end" shape="round" @click="activateConfetti()" color="success">
              できた！
              </ion-button>
           </ion-item>
            <ion-card-header>
              <ion-card-subtitle>{{missionTitle}}</ion-card-subtitle>
            </ion-card-header>
            <ion-card-content>
            {{missionStatement}}
            </ion-card-content>
          </ion-card>
        </div>
        <div class="outer" v-else>
          <div class="inner missionCard">
            <ion-button shape="round" size="large" expand="full" @click="showMsg()">
              <ion-icon slot="start" :icon="rocketOutline"/>本日のミッションに挑戦!
            </ion-button>
          </div>
        </div>
          </transition>
        </ion-col>
      </ion-row>
    </ion-grid>
    <img class="constellation" src="https://cdn.gifted-pocket.com/images/constellation.png" />
  </ion-content>
 </ion-page>
</template>

<script>
import { IonPage,
         IonContent,
         IonButton,
         IonGrid,
         IonRow,
         IonCol,
         IonCard,
         IonCardHeader,
         IonCardContent,
         alertController
          } from '@ionic/vue';
import  SyaberuMenu from './SyaberuMenu.vue';
import  getMission from './helper/getMission.js';
import { 
rocketOutline,
sparklesOutline
} from 'ionicons/icons';
/** Const section */
const FUNCTIONS_HOST_NAME = 'https://us-central1-attendancerecorder-admin.cloudfunctions.net';
const ACHIVEMENT = FUNCTIONS_HOST_NAME + "/achivement";
export default  {
  name: 'HelloStampRally',
  components: { 
    IonContent, 
    IonPage,
    IonButton,
    SyaberuMenu,
    IonCard,
         IonCardHeader,
         IonCardContent,
         IonGrid,
         IonRow,
         IonCol,
     },
     ConfettiExplosion,
  setup() {
      return {
        rocketOutline,
        sparklesOutline
      }
  },
  data(){
    return {
      msg : '',
      show : false,
      congrats : false,
      missions : null,
      currentIndex : -1,
      tenantId : null,
      userId : null,
      particleCount : 100,
    }
  },
  async mounted(){
    const user = this.$store.state.activeUser;
    this.tenantId = user.tenantId;
    this.userId = user.userId;
    await this.load(this.tenantId,this.userId);
    this.msg = `ボタンをおして、今日のミッションにチャレンジだ！`
    this.dayX = 'DAY ' + this.missions[this.currentIndex].no;
    this.missionTitle = this.missions[this.currentIndex].title;
    this.missionStatement = this.missions[this.currentIndex].mission;
  },
  methods : {
    async loadAchivement(tenantId,userId){
      let params = { "params" : {
        'tenantId' : tenantId,
        'userId' : userId,
      }};
      return this.axios.get(ACHIVEMENT,params);
    },
    async load(tenantId,userId){
      this.$root.startLoading();
      let tmpMissions = await getMission(this.axios,tenantId,userId,this.$store);
      this.achivements = await this.loadAchivement(tenantId,userId);
      tmpMissions.forEach(tM => {
        this.missions = tM.missions;
      });
      this.currentIndex = this.achivements.data.stage;
      this.$root.endLoading();
    },
    async updateIndex(tenantId,userId){
      let params = {
        tenantId,userId,
      };
      return this.axios.put(ACHIVEMENT,params);
    },
    showMsg(){
      if(this.show){
        this.show = false;
      }else{
        this.show = true;
      }
    },
    activateConfetti(){
      this.congrats = true;
      this.presentAlert();
    },
    getMessage(){
      let exactNo = this.currentIndex + 1;
      if(exactNo % 10 == 0){
        //FIXME: ここはお祝いなので。もっと多様性のある褒め方をするべきだが、とりあえず。１０回刻み
        //どんどん、豪華になる感じ
        this.particleCount = this.particleCount * exactNo;
        return `すごい!!、${exactNo}回もミッション成功したよ!`;
      }else{
        return 'エラい! 今日もよく頑張りました!!';
      }
    },
    async presentAlert() {
      const alert = await alertController
        .create({
          cssClass: 'my-custom-class',
          header: this.getMessage(),
          message: '自分も子どもたちも褒めてあげましょう！！明日も良い日になりますように！',
          buttons: [{
            'text' : 'OK',
             handler: () => {
                this.updateIndex(this.tenantId,this.userId);
                this.$router.push('/missionBoard');
             },
          }]
        });
      await alert.present();
    },
  }
}
</script>
<style>
.constellation {
  position: absolute;
  top: 350px;
}
.missionCard{
  width: auto;
  height: 300px;
}
.outer{
  display: table;
  width: 100%;
}
.inner{
  display: table-cell;
  margin: 10px;
  vertical-align: middle;
}
</style>
